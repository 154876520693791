(function(g,f){typeof exports==='object'&&typeof module!=='undefined'?module.exports=f():typeof define==='function'&&define.amd?define(f):(g=g||self,g['@danielkalen/polyfills']=f());}(this,function(){'use strict';!function (e) {
  function t(e) {
    var t = e.nodeName.toUpperCase();
    return "TEXTAREA" === t || "INPUT" === t;
  }

  function n(e) {
    m = {
      el: e,
      value: e.value
    }, d = Object.getOwnPropertyDescriptor(e.constructor.prototype, "value"), Object.defineProperty(m.el, "value", p), m.el.attachEvent("onpropertychange", o);
  }

  function u() {
    m.el && (delete m.el.value, m.el.detachEvent("onpropertychange", o), m = {
      el: null,
      value: null
    }, d = null);
  }

  function c(t) {
    a ? t.fireEvent("on" + e, i()) : t.dispatchEvent(i());
  }

  function o(e) {
    if ("value" === e.propertyName) {
      var t = e.srcElement.value;
      t !== m.value && (m.value = t, c(m.el));
    }
  }

  function l() {
    m.el && m.el.value !== m.value && (m.value = m.el.value, c(m.el));
  }

  var a = "undefined" == typeof document.dispatchEvent,
      r = document.createElement("input"),
      v = "oninput" in r && (!("documentMode" in document) || document.documentMode > 9),
      i = function () {
    var t;
    return a ? (t = document.createEventObject(), t.type = "change", t.bubbles = !0, t.cancelable = !1) : (t = document.createEvent("CustomEvent"), t.initCustomEvent(e, !0, !1, {})), t;
  },
      d = null,
      p = {
    get: function () {
      return d.get.call(this);
    },
    set: function (e) {
      m.value = e, d.set.call(this, e);
    }
  },
      m = {
    el: null,
    value: null
  };

  v ? "input" !== e && document.addEventListener("input", function (e) {
    c(e.target);
  }, !1) : (document.attachEvent("onfocusin", function (e) {
    t(e.srcElement) && (u(), n(e.srcElement));
  }), document.attachEvent("onfocusout", function () {
    u();
  }), document.attachEvent("onselectionchange", l), document.attachEvent("onkeyup", l), document.attachEvent("onkeydown", l));
}("input");!function (e, t, l) {
  function n(e) {
    function t() {
      return i = h(e);
    }

    function l() {
      var l = i,
          n = t();
      l !== n && e.__placeholder && (e.value = n), e.__placeholder && e.value !== n && (e.__placeholder = !1);
    }

    function n() {
      e.value ? c() : a();
    }

    function a() {
      e.__placeholder || e.value || r();
    }

    function r() {
      e.__placeholder = !0, e.value = t(), d(e, "-placeholder");
    }

    function c() {
      e.__placeholder && (e.__placeholder = !1, e.value = "", p(e, "-placeholder"));
    }

    if (null != e.__placeholder) return void (e.__placeholder && (e.value = t()));
    var i = h(e);
    e.value ? (e.__placeholder = !1, e.value === t() && r()) : a(), o(e, "keyup", n), o(e, "keyDown", n), o(e, "blur", n), o(e, "focus", c), o(e, "click", c), e.addEventListener ? o(e, "DOMAttrModified", l) : e.attachEvent && "onpropertychange" in e && o(e, "propertychange", l);
  }

  function a() {
    var e = [];
    return c(arguments, function (t) {
      "number" != typeof t.length && (t = [t]), e.push.apply(e, i(t, r));
    }), e;
  }

  function r(e) {
    var t = (e.nodeName || "").toLowerCase();
    return "textarea" === t || "input" === t && ("text" === e.type || "password" === e.type);
  }

  function o(e, t, l) {
    e.addEventListener ? e.addEventListener(t, l, !1) : e.attachEvent && e.attachEvent("on" + t, l);
  }

  function c(e, t) {
    if (e.forEach) return e.forEach(t);

    for (var l = 0, n = e.length; n > l; l++) t.call(null, e[l], l, e);
  }

  function i(e, t) {
    if (e.filter) return e.filter(t);

    for (var l = [], n = 0, a = e.length; a > n; n++) t.call(null, e[n], n, e) && l.push(e[n]);

    return l;
  }

  function u(e) {
    return y[e] || (y[e] = new RegExp("(^|\\s)+" + e + "(\\s|$)+", "g")), y[e];
  }

  function d(e, t) {
    e.className += " " + t;
  }

  function p(e, t) {
    e.className = e.className.replace(u(t), " ");
  }

  function h(e) {
    return e.getAttribute("placeholder") || e.attributes.placeholder && e.attributes.placeholder.nodeValue;
  }

  function f() {
    var e = t.styleSheets && t.styleSheets[0];

    if (!e) {
      var l = (t.head || t.getElementsByTagName("head")[0], t.createElement("style"));
      l.appendChild(t.createTextNode("")), t.head.appendChild(l), e = l.sheet;
    }

    return e;
  }

  if ("placeholder" in t.createElement("input")) return t.placeholderPolyfill = function () {}, void (t.placeholderPolyfill.active = !1);
  var s = t.getElementsByTagName("input"),
      v = t.getElementsByTagName("textarea");
  t.placeholderPolyfill = function (e) {
    e = e ? a(e) : a(s, v), c(e, n);
  }, t.placeholderPolyfill.active = !0, t.placeholderPolyfill(), t.addEventListener ? (t.addEventListener("DOMAttrModified", t.placeholderPolyfill), t.addEventListener("DOMNodeInserted", t.placeholderPolyfill)) : t.attachEvent && "onpropertychange" in t && t.attachEvent("onpropertychange", t.placeholderPolyfill), f().addRule(".-placeholder", "color: #888;", 0);
  var y = {};
}(window, document);var commonjsGlobal = typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

function createCommonjsModule(fn, module) {
	return module = { exports: {} }, fn(module, module.exports), module.exports;
}var collections = createCommonjsModule(function (module, exports) {
  (function (e) {
    function f(a, c) {
      function b(a) {
        if (!this || this.constructor !== b) return new b(a);
        this._keys = [];
        this._values = [];
        this._itp = [];
        this.objectOnly = c;
        a && v.call(this, a);
      }

      c || w(a, "size", {
        get: x
      });
      a.constructor = b;
      b.prototype = a;
      return b;
    }

    function v(a) {
      this.add ? a.forEach(this.add, this) : a.forEach(function (a) {
        this.set(a[0], a[1]);
      }, this);
    }

    function d(a) {
      this.has(a) && (this._keys.splice(b, 1), this._values.splice(b, 1), this._itp.forEach(function (a) {
        b < a[0] && a[0]--;
      }));
      return -1 < b;
    }

    function m(a) {
      return this.has(a) ? this._values[b] : void 0;
    }

    function n(a, c) {
      if (this.objectOnly && c !== Object(c)) throw new TypeError("Invalid value used as weak collection key");
      if (c != c || 0 === c) for (b = a.length; b-- && !y(a[b], c););else b = a.indexOf(c);
      return -1 < b;
    }

    function p(a) {
      return n.call(this, this._values, a);
    }

    function q(a) {
      return n.call(this, this._keys, a);
    }

    function r(a, c) {
      this.has(a) ? this._values[b] = c : this._values[this._keys.push(a) - 1] = c;
      return this;
    }

    function t(a) {
      this.has(a) || this._values.push(a);
      return this;
    }

    function h() {
      (this._keys || 0).length = this._values.length = 0;
    }

    function z() {
      return k(this._itp, this._keys);
    }

    function l() {
      return k(this._itp, this._values);
    }

    function A() {
      return k(this._itp, this._keys, this._values);
    }

    function B() {
      return k(this._itp, this._values, this._values);
    }

    function k(a, c, b) {
      var g = [0],
          e = !1;
      a.push(g);
      return {
        next: function () {
          var f,
              d = g[0];
          !e && d < c.length ? (f = b ? [c[d], b[d]] : c[d], g[0]++) : (e = !0, a.splice(a.indexOf(g), 1));
          return {
            done: e,
            value: f
          };
        }
      };
    }

    function x() {
      return this._values.length;
    }

    function u(a, c) {
      for (var b = this.entries();;) {
        var d = b.next();
        if (d.done) break;
        a.call(c, d.value[1], d.value[0], this);
      }
    }

    var b,
        w = Object.defineProperty,
        y = function (a, b) {
      return isNaN(a) ? isNaN(b) : a === b;
    };

    "undefined" == typeof WeakMap && (e.WeakMap = f({
      "delete": d,
      clear: h,
      get: m,
      has: q,
      set: r
    }, !0));
    "undefined" != typeof Map && "function" === typeof new Map().values && new Map().values().next || (e.Map = f({
      "delete": d,
      has: q,
      get: m,
      set: r,
      keys: z,
      values: l,
      entries: A,
      forEach: u,
      clear: h
    }));
    "undefined" != typeof Set && "function" === typeof new Set().values && new Set().values().next || (e.Set = f({
      has: p,
      add: t,
      "delete": d,
      clear: h,
      keys: l,
      values: l,
      entries: B,
      forEach: u
    }));
    "undefined" == typeof WeakSet && (e.WeakSet = f({
      "delete": d,
      add: t,
      clear: h,
      has: p
    }, !0));
  })("undefined" != typeof commonjsGlobal ? commonjsGlobal : window);
});(function () {
  if (typeof window.Element === "undefined" || "classList" in document.documentElement) return;
  var prototype = Array.prototype,
      push = prototype.push,
      splice = prototype.splice,
      join = prototype.join;

  function DOMTokenList(el) {
    this.el = el; // The className needs to be trimmed and split on whitespace
    // to retrieve a list of classes.

    var classes = el.className.replace(/^\s+|\s+$/g, '').split(/\s+/);

    for (var i = 0; i < classes.length; i++) {
      push.call(this, classes[i]);
    }
  }
  DOMTokenList.prototype = {
    add: function (token) {
      if (this.contains(token)) return;
      push.call(this, token);
      this.el.className = this.toString();
    },
    contains: function (token) {
      return this.el.className.indexOf(token) != -1;
    },
    item: function (index) {
      return this[index] || null;
    },
    remove: function (token) {
      if (!this.contains(token)) return;

      for (var i = 0; i < this.length; i++) {
        if (this[i] == token) break;
      }

      splice.call(this, i, 1);
      this.el.className = this.toString();
    },
    toString: function () {
      return join.call(this, ' ');
    },
    toggle: function (token) {
      if (!this.contains(token)) {
        this.add(token);
      } else {
        this.remove(token);
      }

      return this.contains(token);
    }
  };
  window.DOMTokenList = DOMTokenList;

  function defineElementGetter(obj, prop, getter) {
    if (Object.defineProperty) {
      Object.defineProperty(obj, prop, {
        get: getter
      });
    } else {
      obj.__defineGetter__(prop, getter);
    }
  }

  defineElementGetter(Element.prototype, 'classList', function () {
    return new DOMTokenList(this);
  });
})();(function () {

  var f,
      g = [];

  function l(a) {
    g.push(a);
    1 == g.length && f();
  }

  function m() {
    for (; g.length;) g[0](), g.shift();
  }

  f = function () {
    setTimeout(m);
  };

  function n(a) {
    this.a = p;
    this.b = void 0;
    this.f = [];
    var b = this;

    try {
      a(function (a) {
        q(b, a);
      }, function (a) {
        r(b, a);
      });
    } catch (c) {
      r(b, c);
    }
  }

  var p = 2;

  function t(a) {
    return new n(function (b, c) {
      c(a);
    });
  }

  function u(a) {
    return new n(function (b) {
      b(a);
    });
  }

  function q(a, b) {
    if (a.a == p) {
      if (b == a) throw new TypeError();
      var c = !1;

      try {
        var d = b && b.then;

        if (null != b && "object" == typeof b && "function" == typeof d) {
          d.call(b, function (b) {
            c || q(a, b);
            c = !0;
          }, function (b) {
            c || r(a, b);
            c = !0;
          });
          return;
        }
      } catch (e) {
        c || r(a, e);
        return;
      }

      a.a = 0;
      a.b = b;
      v(a);
    }
  }

  function r(a, b) {
    if (a.a == p) {
      if (b == a) throw new TypeError();
      a.a = 1;
      a.b = b;
      v(a);
    }
  }

  function v(a) {
    l(function () {
      if (a.a != p) for (; a.f.length;) {
        var b = a.f.shift(),
            c = b[0],
            d = b[1],
            e = b[2],
            b = b[3];

        try {
          0 == a.a ? "function" == typeof c ? e(c.call(void 0, a.b)) : e(a.b) : 1 == a.a && ("function" == typeof d ? e(d.call(void 0, a.b)) : b(a.b));
        } catch (h) {
          b(h);
        }
      }
    });
  }

  n.prototype.g = function (a) {
    return this.c(void 0, a);
  };

  n.prototype.c = function (a, b) {
    var c = this;
    return new n(function (d, e) {
      c.f.push([a, b, d, e]);
      v(c);
    });
  };

  function w(a) {
    return new n(function (b, c) {
      function d(c) {
        return function (d) {
          h[c] = d;
          e += 1;
          e == a.length && b(h);
        };
      }

      var e = 0,
          h = [];
      0 == a.length && b(h);

      for (var k = 0; k < a.length; k += 1) u(a[k]).c(d(k), c);
    });
  }

  function x(a) {
    return new n(function (b, c) {
      for (var d = 0; d < a.length; d += 1) u(a[d]).c(b, c);
    });
  }
  window.Promise || (window.Promise = n, window.Promise.resolve = u, window.Promise.reject = t, window.Promise.race = x, window.Promise.all = w, window.Promise.prototype.then = n.prototype.c, window.Promise.prototype["catch"] = n.prototype.g);
})();if (!Object.values) {
  Object.defineProperty(Object, 'values', {
    value: function (target) {
      return Object.keys(target).map(function (key) {
        return target[key];
      });
    }
  });
}if (!Object.entries) {
  Object.defineProperty(Object, 'entries', {
    value: function (target) {
      return Object.keys(target).map(function (key) {
        return [key, target[key]];
      });
    }
  });
}var definePolyfill = function (target, property, value) {
  if (!target.prototype[property]) {
    if (Object.defineProperty) {
      return Object.defineProperty(target.prototype, property, {
        writable: true,
        configurable: true,
        enumerable: false,
        value: value
      });
    } else {
      return target.prototype[property] = value;
    }
  }
};var definePolyfill$1;
definePolyfill$1 = definePolyfill;
definePolyfill$1(Array, 'find', function (callback, thisArg) {
  var val,
      i = 0,
      elmVal;

  for (i; i < this.length; i++) {
    elmVal = this[i];

    if (callback.call(thisArg, elmVal, i, this)) {
      return elmVal;
    }
  }

  return val;
});var definePolyfill$2;
definePolyfill$2 = definePolyfill;
definePolyfill$2(Array, 'findIndex', function (callback, thisArg) {
  var i = 0,
      elmVal;

  for (i; i < this.length; i++) {
    elmVal = this[i];

    if (callback.call(thisArg, elmVal, i, this)) {
      return i;
    }
  }

  return -1;
});var definePolyfill$3;
definePolyfill$3 = definePolyfill;
definePolyfill$3(Array, 'forEach', function (fn, arg) {
  var arr = this,
      len = arr.length,
      thisArg = arg ? arg : undefined,
      i;

  for (i = 0; i < len; i += 1) {
    if (arr.hasOwnProperty(i)) {
      fn.call(thisArg, arr[i], i, arr);
    }
  }
});var definePolyfill$4;
definePolyfill$4 = definePolyfill;
definePolyfill$4(Array, 'from', function (object) {
  return Array.prototype.slice.call(object);
});var definePolyfill$5;
definePolyfill$5 = definePolyfill;
definePolyfill$5(Array, 'fill', function (value, start, end) {
  var i,
      l,
      length = this.length;
  start = parseInt(start, 10) || 0;
  end = end === undefined ? length : parseInt(end, 10) || 0;

  if (start < 0) {
    i = Math.max(length + start, 0);
  } else {
    i = Math.min(start, length);
  }

  if (end < 0) {
    l = Math.max(length + end, 0);
  } else {
    l = Math.min(end, length);
  }

  for (; i < l; i++) {
    this[i] = value;
  }

  return this;
});var definePolyfill$6;
definePolyfill$6 = definePolyfill;
definePolyfill$6(Array, 'includes', function (searchElement) {
  return this.indexOf(searchElement) !== -1;
});var definePolyfill$7;
definePolyfill$7 = definePolyfill;
definePolyfill$7(Array, 'isArray', function (arg) {
  return Object.prototype.toString.call(arg) === '[object Array]';
});var definePolyfill$8;
definePolyfill$8 = definePolyfill;
definePolyfill$8(String, 'includes', function (str, start) {
  return this.indexOf(str, start || 0) !== -1;
});var definePolyfill$9;
definePolyfill$9 = definePolyfill;
definePolyfill$9(String, 'startsWith', function (str, pos) {
  return this.indexOf(str, pos || 0) === 0;
});var definePolyfill$a;
definePolyfill$a = definePolyfill;
definePolyfill$a(String, 'endsWith', function (str) {
  return this.indexOf(str, this.length - str.length) !== -1;
});var definePolyfill$b;
definePolyfill$b = definePolyfill;
definePolyfill$b(String, 'repeat', function (n) {
  var rpt = '',
      i;

  for (i = 0; i < n; i++) {
    rpt += str;
  }

  return rpt;
});var registerPolyfills;

registerPolyfills = function () {}; // registerPolyfills.version = import '../package.json $ version'


var src = registerPolyfills;return src;}));