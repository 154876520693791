## ==========================================================================
## Array
## ========================================================================== 
# require('sugar/array/add')
# require('sugar/array/append')
# require('sugar/array/at')
# require('sugar/array/clone')
# require('sugar/array/compact')
# require('sugar/array/exclude')
# require('sugar/array/first')
# require('sugar/array/flatten')
# require('sugar/array/from')
require('sugar/array/groupBy')
require('sugar/polyfills/array/includes')
# require('sugar/array/inGroups')
# require('sugar/array/inGroupsOf')
# require('sugar/array/intersect')
# require('sugar/array/isEmpty')
# require('sugar/array/isEqual')
# require('sugar/array/last')
# require('sugar/array/remove')
# require('sugar/array/removeAt')
# require('sugar/array/sample')
# require('sugar/array/shuffle')
require('sugar/array/sortBy')
# require('sugar/array/subtract')
# require('sugar/array/to')
# require('sugar/array/union')
require('sugar/array/unique')
# require('sugar/array/zip')

# require('sugar/array/average')
# require('sugar/array/count')
# require('sugar/array/every')
# require('sugar/array/everyFromIndex')
require('sugar/array/filter')
# require('sugar/array/filterFromIndex')
require('sugar/array/find')
# require('sugar/array/findFromIndex')
# require('sugar/array/findIndex')
# require('sugar/array/findIndexFromIndex')
# require('sugar/array/forEachFromIndex')
# require('sugar/array/least')
require('sugar/array/map')
# require('sugar/array/mapFromIndex')
# require('sugar/array/max')
# require('sugar/array/median')
# require('sugar/array/min')
# require('sugar/array/most')
# require('sugar/array/none')
# require('sugar/array/reduceFromIndex')
# require('sugar/array/reduceRightFromIndex')
# require('sugar/array/some')
# require('sugar/array/someFromIndex')
# require('sugar/array/sum')


## ==========================================================================
## Object
## ========================================================================== 
# require('sugar/object/add')
# require('sugar/object/addAll')
# require('sugar/object/clone')
# require('sugar/object/defaults')
# require('sugar/object/exclude')
# require('sugar/object/fromQueryString')
# require('sugar/object/get')
# require('sugar/object/has')
# require('sugar/object/intersect')
# require('sugar/object/invert')
# require('sugar/object/isArguments')
# require('sugar/object/isArray')
# require('sugar/object/isBoolean')
# require('sugar/object/isDate')
# require('sugar/object/isEmpty')
# require('sugar/object/isEqual')
# require('sugar/object/isError')
# require('sugar/object/isFunction')
# require('sugar/object/isMap')
# require('sugar/object/isNumber')
# require('sugar/object/isObject')
# require('sugar/object/isRegExp')
# require('sugar/object/isSet')
# require('sugar/object/isString')
# require('sugar/object/merge')
# require('sugar/object/mergeAll')
# require('sugar/object/reject')
# require('sugar/object/remove')
# require('sugar/object/select')
# require('sugar/object/set')
# require('sugar/object/size')
# require('sugar/object/subtract')
# require('sugar/object/tap')
# require('sugar/object/toQueryString')
# require('sugar/object/values')

# require('sugar/object/average')
# require('sugar/object/count')
# require('sugar/object/every')
# require('sugar/object/filter')
# require('sugar/object/find')
# require('sugar/object/forEach')
# require('sugar/object/least')
# require('sugar/object/map')
# require('sugar/object/max')
# require('sugar/object/median')
# require('sugar/object/min')
# require('sugar/object/most')
# require('sugar/object/none')
# require('sugar/object/reduce')
# require('sugar/object/some')
# require('sugar/object/sum')



## ==========================================================================
## Function
## ========================================================================== 
# require('sugar/function/after')
# require('sugar/function/cancel')
# require('sugar/function/debounce')
# require('sugar/function/delay')
# require('sugar/function/every')
# require('sugar/function/lazy')
# require('sugar/function/lock')
# require('sugar/function/memoize')
# require('sugar/function/once')
# require('sugar/function/partial')
# require('sugar/function/throttle')




## ==========================================================================
## Number
## ========================================================================== 
# require('sugar/number/random')
# require('sugar/number/abbr')
# require('sugar/number/abs')
# require('sugar/number/acos')
# require('sugar/number/asin')
# require('sugar/number/atan')
# require('sugar/number/bytes')
# require('sugar/number/ceil')
# require('sugar/number/chr')
# require('sugar/number/cos')
# require('sugar/number/exp')
# require('sugar/number/floor')
# require('sugar/number/format')
# require('sugar/number/hex')
# require('sugar/number/isEven')
# require('sugar/number/isInteger')
# require('sugar/number/isMultipleOf')
# require('sugar/number/isOdd')
# require('sugar/number/log')
# require('sugar/number/metric')
# require('sugar/number/ordinalize')
# require('sugar/number/pad')
# require('sugar/number/pow')
# require('sugar/number/round')
# require('sugar/number/sin')
# require('sugar/number/sqrt')
# require('sugar/number/tan')
# require('sugar/number/times')
# require('sugar/number/toNumber')




## ==========================================================================
## String
## ========================================================================== 
# require('sugar/string/at')
# require('sugar/string/camelize')
# require('sugar/string/capitalize')
# require('sugar/string/chars')
# require('sugar/string/codes')
# require('sugar/string/compact')
# require('sugar/string/dasherize')
# require('sugar/string/decodeBase64')
# require('sugar/string/encodeBase64')
# require('sugar/string/escapeHTML')
# require('sugar/string/escapeURL')
# require('sugar/string/first')
# require('sugar/string/forEach')
# require('sugar/string/format')
# require('sugar/string/from')
# require('sugar/string/includes')
# require('sugar/string/insert')
# require('sugar/string/isBlank')
# require('sugar/string/isEmpty')
# require('sugar/string/last')
# require('sugar/string/lines')
# require('sugar/string/pad')
# require('sugar/string/padLeft')
# require('sugar/string/padRight')
# require('sugar/string/parameterize')
# require('sugar/string/remove')
# require('sugar/string/removeAll')
# require('sugar/string/removeTags')
# require('sugar/string/replaceAll')
# require('sugar/string/reverse')
# require('sugar/polyfills/string/repeat')
# require('sugar/string/shift')
# require('sugar/string/spacify')
# require('sugar/string/stripTags')
# require('sugar/string/titleize')
# require('sugar/string/to')
# require('sugar/string/toNumber')
# require('sugar/string/trimLeft')
# require('sugar/string/trimRight')
# require('sugar/string/truncate')
# require('sugar/string/truncateOnWord')
# require('sugar/string/underscore')
# require('sugar/string/unescapeHTML')
# require('sugar/string/unescapeURL')
# require('sugar/string/words')



## ==========================================================================
## RegExp
## ========================================================================== 
# require('sugar/regexp/escape')
# require('sugar/regexp/addFlags')
# require('sugar/regexp/getFlags')
# require('sugar/regexp/removeFlags')
# require('sugar/regexp/setFlags')




## ==========================================================================
## Date
## ========================================================================== 
# require('sugar/date/create')
# require('sugar/date/addDays')
# require('sugar/date/addHours')
# require('sugar/date/addMilliseconds')
# require('sugar/date/addMinutes')
# require('sugar/date/addMonths')
# require('sugar/date/addSeconds')
# require('sugar/date/addWeeks')
# require('sugar/date/addYears')
# require('sugar/date/advance')
# require('sugar/date/beginningOfDay')
# require('sugar/date/beginningOfISOWeek')
# require('sugar/date/beginningOfMonth')
# require('sugar/date/beginningOfWeek')
# require('sugar/date/beginningOfYear')
# require('sugar/date/clone')
# require('sugar/date/daysAgo')
# require('sugar/date/daysFromNow')
# require('sugar/date/daysInMonth')
# require('sugar/date/daysSince')
# require('sugar/date/daysUntil')
# require('sugar/date/endOfDay')
# require('sugar/date/endOfISOWeek')
# require('sugar/date/endOfMonth')
# require('sugar/date/endOfWeek')
# require('sugar/date/endOfYear')
# require('sugar/date/format')
# require('sugar/date/get')
# require('sugar/date/getISOWeek')
# require('sugar/date/getUTCOffset')
# require('sugar/date/getUTCWeekday')
# require('sugar/date/getWeekday')
# require('sugar/date/hoursAgo')
# require('sugar/date/hoursFromNow')
# require('sugar/date/hoursSince')
# require('sugar/date/hoursUntil')
# require('sugar/date/is')
# require('sugar/date/isAfter')
# require('sugar/date/isBefore')
# require('sugar/date/isBetween')
# require('sugar/date/isFriday')
# require('sugar/date/isFuture')
# require('sugar/date/isLastMonth')
# require('sugar/date/isLastWeek')
# require('sugar/date/isLastYear')
# require('sugar/date/isLeapYear')
# require('sugar/date/isMonday')
# require('sugar/date/isNextMonth')
# require('sugar/date/isNextWeek')
# require('sugar/date/isNextYear')
# require('sugar/date/isPast')
# require('sugar/date/isSaturday')
# require('sugar/date/isSunday')
# require('sugar/date/isThisMonth')
# require('sugar/date/isThisWeek')
# require('sugar/date/isThisYear')
# require('sugar/date/isThursday')
# require('sugar/date/isToday')
# require('sugar/date/isTomorrow')
# require('sugar/date/isTuesday')
# require('sugar/date/isUTC')
# require('sugar/date/isValid')
# require('sugar/date/isWednesday')
# require('sugar/date/isWeekday')
# require('sugar/date/isWeekend')
# require('sugar/date/isYesterday')
# require('sugar/date/iso')
# require('sugar/date/millisecondsAgo')
# require('sugar/date/millisecondsFromNow')
# require('sugar/date/millisecondsSince')
# require('sugar/date/millisecondsUntil')
# require('sugar/date/minutesAgo')
# require('sugar/date/minutesFromNow')
# require('sugar/date/minutesSince')
# require('sugar/date/minutesUntil')
# require('sugar/date/monthsAgo')
# require('sugar/date/monthsFromNow')
# require('sugar/date/monthsSince')
# require('sugar/date/monthsUntil')
# require('sugar/date/relative')
# require('sugar/date/relativeTo')
# require('sugar/date/reset')
# require('sugar/date/rewind')
# require('sugar/date/secondsAgo')
# require('sugar/date/secondsFromNow')
# require('sugar/date/secondsSince')
# require('sugar/date/secondsUntil')
# require('sugar/date/set')
# require('sugar/date/setISOWeek')
# require('sugar/date/setUTC')
# require('sugar/date/setWeekday')
# require('sugar/date/weeksAgo')
# require('sugar/date/weeksFromNow')
# require('sugar/date/weeksSince')
# require('sugar/date/weeksUntil')
# require('sugar/date/yearsAgo')
# require('sugar/date/yearsFromNow')
# require('sugar/date/yearsSince')
# require('sugar/date/yearsUntil')

# require('sugar/number/day')
# require('sugar/number/dayAfter')
# require('sugar/number/dayAgo')
# require('sugar/number/dayBefore')
# require('sugar/number/dayFromNow')
# require('sugar/number/days')
# require('sugar/number/daysAfter')
# require('sugar/number/daysAgo')
# require('sugar/number/daysBefore')
# require('sugar/number/daysFromNow')
# require('sugar/number/duration')
# require('sugar/number/hour')
# require('sugar/number/hourAfter')
# require('sugar/number/hourAgo')
# require('sugar/number/hourBefore')
# require('sugar/number/hourFromNow')
# require('sugar/number/hours')
# require('sugar/number/hoursAfter')
# require('sugar/number/hoursAgo')
# require('sugar/number/hoursBefore')
# require('sugar/number/hoursFromNow')
# require('sugar/number/millisecond')
# require('sugar/number/millisecondAfter')
# require('sugar/number/millisecondAgo')
# require('sugar/number/millisecondBefore')
# require('sugar/number/millisecondFromNow')
# require('sugar/number/milliseconds')
# require('sugar/number/millisecondsAfter')
# require('sugar/number/millisecondsAgo')
# require('sugar/number/millisecondsBefore')
# require('sugar/number/millisecondsFromNow')
# require('sugar/number/minute')
# require('sugar/number/minuteAfter')
# require('sugar/number/minuteAgo')
# require('sugar/number/minuteBefore')
# require('sugar/number/minuteFromNow')
# require('sugar/number/minutes')
# require('sugar/number/minutesAfter')
# require('sugar/number/minutesAgo')
# require('sugar/number/minutesBefore')
# require('sugar/number/minutesFromNow')
# require('sugar/number/month')
# require('sugar/number/monthAfter')
# require('sugar/number/monthAgo')
# require('sugar/number/monthBefore')
# require('sugar/number/monthFromNow')
# require('sugar/number/months')
# require('sugar/number/monthsAfter')
# require('sugar/number/monthsAgo')
# require('sugar/number/monthsBefore')
# require('sugar/number/monthsFromNow')
# require('sugar/number/second')
# require('sugar/number/secondAfter')
# require('sugar/number/secondAgo')
# require('sugar/number/secondBefore')
# require('sugar/number/secondFromNow')
# require('sugar/number/seconds')
# require('sugar/number/secondsAfter')
# require('sugar/number/secondsAgo')
# require('sugar/number/secondsBefore')
# require('sugar/number/secondsFromNow')
# require('sugar/number/week')
# require('sugar/number/weekAfter')
# require('sugar/number/weekAgo')
# require('sugar/number/weekBefore')
# require('sugar/number/weekFromNow')
# require('sugar/number/weeks')
# require('sugar/number/weeksAfter')
# require('sugar/number/weeksAgo')
# require('sugar/number/weeksBefore')
# require('sugar/number/weeksFromNow')
# require('sugar/number/year')
# require('sugar/number/yearAfter')
# require('sugar/number/yearAgo')
# require('sugar/number/yearBefore')
# require('sugar/number/yearFromNow')
# require('sugar/number/years')
# require('sugar/number/yearsAfter')
# require('sugar/number/yearsAgo')
# require('sugar/number/yearsBefore')
# require('sugar/number/yearsFromNow')

require('sugar-core').extend()